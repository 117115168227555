<div class="link">
  @if (!inProgress) {
    <a [href]="downloadLinkAuth">{{ id }}</a>
  } @else {
    <span>{{ id.substring(1) }}</span>
  }
</div>
<div class="stack">
  <div class="info">
    @if (inProgress) {
      <span i18n>in progress...</span>
    }
    @if (size) {
      <span>{{ fileSize }}</span>
    }
  </div>
  <div class="actions">
    <a [routerLink]="['/ref', downloadLink]" i18n>ref</a>
    @if (store.account.admin) {
      @if (!inProgress) {
        <app-confirm-action [action]="restore$">restore</app-confirm-action>
      }
      <app-confirm-action [action]="delete$">delete</app-confirm-action>
    }

  </div>
</div>

@for (e of serverError; track e) {
  <div class="error">{{ e }}</div>
}
