<ng-container [formGroup]="group">

  <label for="tag" i18n>Tag:</label>
  <div class="form-array">
    <input #tagInput
           id="tag"
           type="text"
           inputmode="email"
           autocorrect="off"
           autocapitalize="none"
           formControlName="tag"
           (keydown)="$event.key === 'Enter' && validate(tagInput) || true"
           (blur)="blur(tagInput)">
    @if (showClear) {
      <button type="button" (click)="clear.next()" i18n-title title="New User" i18n>🆑️</button>
    }
  </div>

  <label for="name" i18n>Name:</label>
  <input id="name" type="text" formControlName="name">

  <label for="role" i18n>Role:</label>
  <select id="role" formControlName="role">
    <option value="ROLE_ADMIN" i18n>admin</option>
    <option value="ROLE_MOD" i18n>mod</option>
    <option value="ROLE_EDITOR" i18n>editor</option>
    <option value="ROLE_USER" i18n>user</option>
    <option value="ROLE_VIEWER" i18n>viewer</option>
    <option value="ROLE_ANONYMOUS" i18n>anon</option>
    <option value="ROLE_BANNED" i18n>banned</option>
  </select>

  <app-tags #notifications
            i18n-label label="Notifications:"
            fieldName="notifications"
            emoji="✉️"
            [group]="group"></app-tags>

  <app-tags #readAccess
            i18n-label label="Read Access:"
            fieldName="readAccess"
            [group]="group"></app-tags>

  <app-tags #writeAccess
            i18n-label label="Write Access:"
            fieldName="writeAccess"
            [group]="group"></app-tags>

  <app-tags #tagReadAccess
            i18n-label label="Tag Read Access:"
            fieldName="tagReadAccess"
            [group]="group"></app-tags>

  <app-tags #tagWriteAccess
            i18n-label label="Tag Write Access:"
            fieldName="tagWriteAccess"
            [group]="group"></app-tags>

  @if (showPubKey) {
    <label for="pubKey" i18n>SSH Public Key:</label>
    <div>
      <textarea id="pubKey"
                formControlName="pubKey"></textarea>
    </div>
  }

  <label for="authorizedKeys" i18n>Authorized Keys:</label>
  <div #fill>
    <textarea id="authorizedKeys"
              formControlName="authorizedKeys"
              [appFillWidth]="fill"></textarea>
  </div>

</ng-container>
