@if (editing || !query) {
  <input #editor
         type="text"
         autocorrect="off"
         autocapitalize="none"
         i18n-placeholder
         placeholder="Tag Query"
         [ngModel]="query"
         (blur)="blur($event)"
         (keydown)="$event.key === 'Enter' && search(editor.value) || true">
  <button type="button" (click)="search(editor.value)">🔎️</button>
} @else {
  <div #div class="breadcrumbs" (click)="$event.target === div && edit(false)">
    @for (breadcrumb of breadcrumbs; track breadcrumb) {
      <span class="crumb">
        @if (breadcrumb.tag) {
          <a class="tag" [routerLink]="['../', breadcrumb.tag]" queryParamsHandling="merge"><span (click)="click($event, breadcrumb)">{{ breadcrumb.text }}</span></a>
        } @else {
          <span class="op" (click)="edit(breadcrumb)">{{ breadcrumb.text }}</span>
        }
      </span>
    }
  </div>
  <button type="button" (click)="edit(true)">✏️</button>
}
