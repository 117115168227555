<div class="row">
  <div *ngIf="store.view.isVoteSorted && store.account.user && admin.getPlugin('plugin/vote/up')"
       class="voting">
    <div class="vote-up"
         [class.on]="upvote"
         (click)="voteUp()"></div>
    <div class="vote-down"
         *ngIf="admin.getPlugin('plugin/vote/down')"
         [class.on]="downvote"
         (click)="voteDown()"></div>
  </div>
  @if (admin.getPlugin('plugin/thumbnail')) {
    @if (thumbnailEmoji || thumbnailColor) {
      <div class="thumbnail"
           [style.background-image]="thumbnailRefs | thumbnail | async | cssUrl"
           [style.background-color]="thumbnailColor"
           [style.border-radius.px]="thumbnailRadius">{{ thumbnailEmoji }}</div>
    } @else if (thumbnail) {
      <div class="thumbnail"
           [style.background-image]="thumbnailRefs | thumbnail: true | async | cssUrl"
           [style.border-radius.px]="thumbnailRadius">{{ thumbnailEmoji }}</div>
    } @else if (thumbnailEmojiDefaults) {
      <div class="thumbnail">{{ thumbnailEmojiDefaults }}</div>
    } @else {
      <div class="thumbnail" [class.default-thumbnail]="true"></div>
    }
  }
  <div class="stack">
    <div class="link"
         [class.remote]="!local"
         [class.comment-title]="!ref.title"
         [class.redundant]="redundantLink"
         [attr.title]="altText">
      @if (tagLink) {
        <app-nav [url]="url" [text]="title"></app-nav>
      } @else if (clickableLink) {
        <app-nav [url]="link" [text]="title" [external]="true"></app-nav>
      } @else {
        <a [routerLink]="defaultView ? ['/ref', url, defaultView] : ['/ref', url]"
           [queryParams]="{ origin: obsoleteOrigin }"
           (click)="saveRef()">{{ title }}</a><wbr>
      }
      <span class="host">({{ host }})</span>
    </div>
    <div class="link-below">
      @if (showToggle && (thread || commentNoTitle || expandPlugins.length)) {
        <button type="button" class="toggle" (click)="toggle()">
          @if (editing || viewSource || expanded) {
            <span class="toggle-x">✕</span>
          } @else {
            <span class="toggle-plus" *ngIf="!fullscreen">＋</span>
            <span class="toggle-fullscreen" *ngIf="fullscreen">⛶</span>
          }
        </button>
      }
      <div class="stack">
        <div class="info"
             [class.expanded]="!actionsExpanded"
             [class.not-expanded]="actionsExpanded === true">
          @if (!ref.created) {
            <span i18n>not found</span>
          } @else {
            @if (publishedIsSubmitted) {
              <span [title]="ref.created.toISO()">
                {{ config.mobile ? '' : submittedLabel }}
                {{ ref.created.toRelative() }}</span>
            } @else {
              <span [title]="ref.published!.toISO()" i18n>
                {{ config.mobile ? '' : publishedLabel }}
                {{ ref.published!.toRelative() }}</span>
            }
            @if (!modifiedIsSubmitted) {
              <span i18n-title title="last edited {{ ref.modified!.toRelative() }}">* </span>
            }
          }
          @if (authors.length) {
            <span i18n> by</span>
            @if (authorExts$ | async; as authorExts) {
              @for (user of authorExts; track user.tag + user.origin) {
                <a class="user tag"
                   [title]="formatAuthor(user.tag + user.origin)"
                   [routerLink]="['/tag', user.tag + user.origin]"
                   [appTitle]="user">{{ user.name || formatAuthor(user.tag) }}</a>
              }
            } @else {
              @for (t of authors; track t) {
                <a class="user tag"
                   [title]="formatAuthor(t)"
                   [routerLink]="['/tag', t]">{{ formatAuthor(t) }}</a>
              }
            }
          }
          @if (tags.length) {
            <span i18n> tagged</span>
            @if (tagExts$ | async; as tagExts) {
              @for (e of tagExts; track e.tag) {
                <a class="tag"
                   [routerLink]="['/tag', e.tag]"
                   [appTitle]="e.tag">{{ e.name || '#' + e.tag }}</a>
              }
            } @else {
              @for (t of tags; track t) {
                <a class="tag"
                   [routerLink]="['/tag', t]">{{ t }}</a>
              }
            }
          }
          @if (recipients.length) {
            <span i18n> to</span>
            @if (recipientExts$ | async; as recipientExts) {
              @for (user of recipientExts; track user.tag + user.origin) {
                <a class="user tag"
                   [title]="formatAuthor(user.tag + user.origin)"
                   [routerLink]="['/tag', user.tag + user.origin]"
                   [appTitle]="user">{{ user.name || formatAuthor(user.tag + user.origin) }}</a>
              }
            } @else {
              @for (t of recipients; track t) {
                <a class="user tag"
                   [title]="formatAuthor(t)"
                   [routerLink]="['/tag', t]">{{ formatAuthor(t) }}</a>
              }
            }
          }
          @if (showAlarm && alarm) {
            <span class="icon" [routerLink]="['/tag', alarm]" i18n-title title="Alarm ({{ alarm }})">🔔</span>
          }
          @for (icon of icons; track icon) {
            @if (showIcon(icon)) {
              <span class="icon"
                    [class.filter-toggle]="icon.tag"
                    (click)="clickIcon(icon, store.hotkey)"
                    [appTitle]="icon"
                    [ref]="ref">{{ icon.label }}</span>
            }
          }
          @for (ui of infoUis; track ui) {
            <app-md class="inline-ui"
                    [disableSanitizer]="true"
                    [origin]="ref.origin"
                    [text]="uiMarkdown(ui.tag)"></app-md>
          }
          @if (ref.created && !local) {
            <span i18n> on</span>
            <a class="origin tag" [routerLink]="['/tag', ref.origin || '*']">{{ ref.origin || 'default' }}</a>
          }
          @if (local) {
            @if (originPull) {
              @if (remoteOrigin) {
                from {{ remoteOrigin }}
              }
              @if (localOrigin) {
                to
                <a class="origin tag" [routerLink]="['/tag', localOrigin]">{{ localOrigin }}</a>
              }
            }
            @if (originPush) {
              @if (localOrigin) {
                from
                <a class="origin tag" [routerLink]="['/tag', localOrigin]">{{ localOrigin }}</a>
              }
              @if (remoteOrigin) {
                to {{ remoteOrigin }}
              }
            }
          }
        </div>
        @if (expanded && expandInline && !editing && !viewSource) {
          <app-viewer class="viewer-inline"
                      [ref]="bareRef"
                      [tags]="plugins || currentTags"
                      [disableResize]="disableResize"
                      (contextmenu)="unlockViewer($event)"
                      (copied)="copied.emit($event)"></app-viewer>
        }
        <div class="actions"
             [class.expanded]="actionsExpanded"
             [class.not-expanded]="actionsExpanded === false">
          @if (store.account.user && !ref.created && !ref.upload) {
            <a routerLink="/submit" [queryParams]="{ url: ref.url }" i18n>submit</a>
          }
          @if (ref.created && !ref.upload) {
            @if (thread) {
              <a [routerLink]="['/ref', ref.url, 'thread']" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>permalink</a>
            } @else if (comment) {
              <a [routerLink]="['/ref', ref.url, 'comments']" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>permalink</a>
            } @else {
              <a [routerLink]="['/ref', ref.url]" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>permalink</a>
            }
            @if (parentCommentTop) {
              <a [routerLink]="['/ref', parentCommentTop, 'comments']" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>top</a>
            }
            @if (parentThreadTop) {
              <a [routerLink]="['/ref', parentThreadTop, 'thread']" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>parent</a>
            }
            @if (store.account.user) {
              <a class="fake-link" (click)="replying = !replying" i18n>reply</a>
            }
            @if (errors) {
              <a [routerLink]="['/ref', ref.url, 'errors']" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>{errors, plural, =1 {1&thinsp;error} other {{{ errors }}&thinsp;errors}}</a>
            }
            @if (comments) {
              <a [routerLink]="['/ref', ref.url, 'comments']" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>{comments, plural, =1 {1&thinsp;comment} other {{{ comments }}&thinsp;comments}}</a>
            } @else if (threads) {
              <a [routerLink]="['/ref', ref.url, 'thread']" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>{threads, plural, =1 {1&thinsp;reply} other {{{ threads }}&thinsp;replies}}</a>
            }
            @if (responses) {
              <a [routerLink]="['/ref', ref.url, 'responses']" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>{feed, select, true {{{ responses }}&thinsp;scraped} false {{responses, plural, =1 {1&thinsp;citation} other {{{ responses }}&thinsp;citations}}}}</a>
            }
            @if (parentComment) {
              <a [routerLink]="['/ref', parentComment, 'comments']" [queryParams]="{ origin: obsoleteOrigin }" i18n>parent</a>
            } @else if (!parentThreadTop) {
              @if (parent) {
                <a [routerLink]="['/ref', parent]" [queryParams]="{ origin: obsoleteOrigin }" i18n>parent</a>
              } @else if (sources) {
                <a [routerLink]="['/ref', ref.url, 'sources']" [queryParams]="{ origin: obsoleteOrigin }" (click)="saveRef()" i18n>{{ sources }}&thinsp;sources</a>
              }
            }
            <app-inline-tag #action *ngIf="taggingAccess" [action]="tag$" i18n>tag</app-inline-tag>
            <app-inline-button #action *ngIf="store.account.user && !local" [action]="copy$" i18n>copy</app-inline-button>
            @if (store.account.user && local) {
              <a routerLink="/submit/web" [queryParams]="{ url, tag: 'plugin/repost' }" i18n>repost</a>
            }
          }
          @if (!hideEdit) {
            @if (ref.created && writeAccess) {
              <a class="fake-link" (click)="editing = !editing" i18n>edit</a>
            } @else {
              <a class="fake-link" (click)="viewSource = !viewSource" i18n>source</a>
            }
          }
          @if (ref.created && !ref.upload && (store.account.mod || taggingAccess)) {
            <app-confirm-action #action
                                [class.force-delete]="deleteAccess"
                                [action]="store.hotkey ? forceDelete$ : delete$" i18n>delete</app-confirm-action>
          }
          @if (ref.upload) {
            @if (ref.exists) {
              <a [routerLink]="['/ref', ref.url]" [queryParams]="{ origin: obsoleteOrigin }" i18n>permalink</a>
            }
            @if (store.account.user) {
              <app-inline-button #action [action]="upload$" i18n>upload</app-inline-button>
              @if (store.account.user && !dm) {
                <a routerLink="/submit/text" [queryParams]="{source: replySources, sourceTitle: ref.title, tag: replyTags}" i18n>reply</a>
              } @else if (store.account.user && dm) {
                <a routerLink="/submit/dm" [queryParams]="{source: replySources, sourceTitle: ref.title, tag: replyTags, to: replyTo}" i18n>reply</a>
              }
              <app-inline-tag #action *ngIf="taggingAccess" [action]="tag$" i18n>tag</app-inline-tag>
              <a class="fake-link" (click)="editing = !editing" i18n>edit</a>
            }
            <app-confirm-action #action [action]="remove$" i18n>remove</app-confirm-action>
          }
          @if (canInvoice) {
            <a routerLink="/submit/invoice"
               [queryParams]="{url: ref.url}" i18n>invoice</a>
          }
          <app-action-list [ref]="ref"
                           [repostRef]="repostRef"
                           [mediaAttachment]="mediaAttachment"
                           [groupedActions]="groupedActions"
                           [groupedAdvancedActions]="groupedAdvancedActions"></app-action-list>
        </div>
      </div>
      <div class="toggle actions-toggle"
           (click)="actionsExpanded = !actionsExpanded">⚙️</div>
      @if (thread || threads) {
        <div class="toggle threads"
             (focus)="delayLastSelected()"
             (pointerdown)="delayLastSelected()"
             [routerLink]="['/ref', top, 'thread']"
             (click)="saveRef()"
             [queryParams]="{ origin: obsoleteOrigin }">🧵️</div>
      } @else if (comment || comments) {
        <div class="toggle comments"
             [routerLink]="['/ref', ref.url, 'comments']"
             [queryParams]="{ origin: obsoleteOrigin }"
             (click)="saveRef()">💬️</div>
      } @else if (admin.getPlugin('plugin/comment')) {
        <div class="toggle comments"
             [routerLink]="['/ref', ref.url]"
             [queryParams]="{ origin: obsoleteOrigin }"
             (click)="saveRef()">💬️</div>
      } @else {
        <div class="toggle view"
             [routerLink]="['/ref', ref.url]"
             [queryParams]="{ origin: obsoleteOrigin }"
             (click)="saveRef()">🖇️️</div>
      }
    </div>
  </div>
</div>

@if (replying) {
  <app-comment-reply [autofocus]="true"
                     [to]="ref"
                     [showCancel]="true"
                     [selectResponseType]="!thread && !comment && !dm"
                     [tags]="replyTags"
                     (save)="onReply($event)"></app-comment-reply>
}
@if (editing) {
  <app-viewer *ngIf="expanded && !expandInline && !editing && !viewSource"
              class="viewer-below"
              [ref]="bareRef"
              [commentControl]="$any(editForm.controls.comment)"
              [tags]="plugins || currentTags"
              [disableResize]="disableResize"
              (contextmenu)="unlockViewer($event)"
              (copied)="copied.emit($event)"></app-viewer>
} @else {
  <app-viewer *ngIf="expanded && !expandInline && !editing && !viewSource"
              class="viewer-below"
              [ref]="bareRef"
              [tags]="plugins || currentTags"
              [disableResize]="disableResize"
              (contextmenu)="unlockViewer($event)"
              (copied)="copied.emit($event)"></app-viewer>
}

@if (editing) {
  <form class="form" [formGroup]="editForm" (ngSubmit)="save()">

    <app-ref-form [group]="editForm"></app-ref-form>

    @for (e of serverError; track e) {
      <span><!-- Unexpected Error --></span>
      <div class="error">{{ e }}</div>
    }

    <span><!-- Buttons --></span>
    <span class="buttons right">
      @if (invalid && !force) {
        <div class="overwrite warning" i18n-title title="Will drop all unknown plugins.">
          <input id="overwrite" type="checkbox" [(ngModel)]="overwrite" [ngModelOptions]="{standalone: true}">
          <label for="overwrite" i18n>Overwrite</label>
        </div>
      }
      @if (invalid && force) {
        <div class="force error" i18n-title title="Will reset all plugins to defaults.">
          <input id="force" type="checkbox" [(ngModel)]="force" [ngModelOptions]="{standalone: true}">
          <label for="force" i18n>Force</label>
        </div>
      }
      @if (submitting) {
        <app-loading [inline]="true"></app-loading>
      }
      <button type="submit" [disabled]="submitting || submitted && !editForm.valid" i18n>save</button>
    <button type="button" [disabled]="submitting" (click)="editing = false" i18n>cancel</button>
  </span>
  </form>
} @else if (viewSource) {
  <form class="form" [formGroup]="editForm">
    <app-ref-form [group]="editForm" [origin]="origin"></app-ref-form>
  </form>
} @else {
  @for (e of serverError; track e) {
    <div class="error">{{ e }}</div>
  }
  <div *ngIf="publishChanged"
       class="warning" i18n>
    * Published date was changed to be after source published date.
  </div>
}
