<form class="form" [formGroup]="originForm">
  <span></span>
  <h2>Backup & Restore</h2>

  <label>Origin:</label>
  <select #originSelect
          formControlName="origin"
          [value]="origin"
          (input)="selectOrigin(originSelect.value)">
    @if (!backupOrigins.includes(origin)) {
      <option [value]="origin">{{ origin || 'default' }}</option>
    }
    @for (origin of backupOrigins; track origin) {
      <option [value]="origin">{{ origin || 'default' }}</option>
    }
  </select>

  <label>Older than:</label>
  <input type="datetime-local" step="1" formControlName="olderThan">

  <span><!-- Buttons --></span>
  <span class="buttons right">
  </span>
</form>

<div class="backup buttons">
  @if (store.account.admin) {
    <button type="button"
            [disabled]="!originForm.valid"
            (click)="backup()"
            i18n-title title="Create Backup" i18n>+ backup</button>
    <button type="button"
            [disabled]="!originForm.valid"
            (click)="deleteOrigin()"
            i18n-title title="Delete all from origin" i18n>&ndash; delete</button>
  }

  @if (!uploading) {
    <button type="button"
            [disabled]="!originForm.valid"
            (click)="uploadFile.click()"
            i18n-title
            title="Upload Backup" i18n>+ upload</button>
  } @else {
    <app-loading></app-loading>
  }
  <input #uploadFile
         type="file"
         class="upload"
         (change)="upload(uploadFile?.files!)">

  @if (store.account.mod) {
    <button type="button"
            [disabled]="!originForm.valid"
            (click)="regen()"
            i18n-title
            title="Regenerate Metadata" i18n>♻️ regen</button>
  }
</div>

@for (e of serverError; track e) {
  <span><!-- Unexpected Error --></span>
  <div class="error">{{ e }}</div>
}

<app-backup-list [list]="list"
                 [origin]="origin"></app-backup-list>
