@if (plugins && plugin) {
  <label [title]="plugin.config?.description">{{ plugin.config?.submitChild || plugin.name || plugin.tag }}</label>
  @if (group) {
    @if (children.length) {
      <div class="ribbon-margin" [style.margin-bottom.px]="childrenOn * 20"></div>
    }
    @if (plugin.config!.form) {
      <formly-form class="form plugin-content"
                   [model]="model"
                   [form]="group"
                   [fields]="plugin.config!.form"
                   [options]="options"></formly-form>
    }
    @if (plugin.config!.advancedForm) {
      <details class="advanced {{ cssClass(plugin.tag) }}"
               [class.mini]="!plugin.config!.form">
        @if (plugin.config!.form) {
          <summary><span i18n>Advanced</span></summary>
        } @else {
          <summary i18n-title title="Advanced"><span></span></summary>
        }
        <formly-form class="form plugin-content"
                     [model]="model"
                     [form]="group"
                     [fields]="plugin.config!.advancedForm"
                     [options]="options"></formly-form>
      </details>
    }
  }
  @for (child of children; track child.tag) {
    @if (plugins.contains(child.tag)) {
      <hr>
      <div>
        <app-form-gen [plugins]="plugins" [plugin]="child"></app-form-gen>
      </div>
    }
  }
}

<div class="floating-ribbons">
  @for (child of children; track child.tag) {
    <div class="icon {{ cssClass(child.tag) }}"
         [class.on]="plugins.contains(child.tag)"
         (click)="toggleChild(child.tag)"><span>{{ child.config?.submitChild }}</span></div>
  }
</div>
