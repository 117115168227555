<ng-container *mobxAutorun>
  @if (query.page) {
    <app-ref-list [page]="query.page"
                  [newRefs$]="newRefs$"
                  emptyMessage=""
                  [showToggle]="false"
                  [expanded]="true"
                  [expandInline]="true"></app-ref-list>
  } @else {
    <app-loading></app-loading>
  }
  @if (store.view.ref) {
    <app-comment-reply [to]="to"
                       quote=""
                       [tags]="replyTags"
                       (save)="newRefs$.next($event)"></app-comment-reply>
  }
</ng-container>
